import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LeftIcon } from "../assets/left.svg";
import axios from "axios";
import { parseJwt } from "../utils";
import { Toaster, toast } from "react-hot-toast";

export const EyeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="w-6 h-6"
    >
      <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
      <path
        fillRule="evenodd"
        d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const EyeClosedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="w-6 h-6"
    >
      <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" />
      <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" />
      <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" />
    </svg>
  );
};

const Login = (props) => {
  const [emailNum, setEmailNum] = useState("");
  const [mode, setMode] = useState("signin");
  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [pin, setPin] = useState("");
  const [showPin, setShowPin] = useState(false);
  const [newPin, setNewPin] = useState("");
  const [showNewPin, setShowNewPin] = useState(false);
  const [confirmPin, setConfirmPin] = useState("");
  const [showConfirmPin, setShowConfirmPin] = useState(false);
  const [sendOtp, setSendOtp] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [boxPositions, setBoxPositions] = useState([]);
  const numOfBoxes = 20;

  const [loader, setLoader] = useState(false);

  const { setAdmin } = props;

  const navigate = useNavigate();

  const switchMode = (mode) => {
    if (mode === "forgot") {
      setSendOtp(true);
    } else {
      setSendOtp(false);
    }
    setEmailNum("");
    setOtp("");
    setShowOtp(false);
    setPin("");
    setShowPin(false);
    setNewPin("");
    setShowNewPin(false);
    setConfirmPin("");
    setShowConfirmPin(false);
    setOtpSent(false);
    setOtpVerified(false);
    setMode(mode);
  };

  const onLogin = async () => {
    setLoader(true);
    try {
      const res = await axios.post("https://console-api.theja.in/login", {
        pin: pin,
        userId: emailNum,
      });

      if (res?.data?.token) {
        localStorage.setItem("krgr_token", res.data.token);
        localStorage.removeItem("krgr_verify_token");
        const data = parseJwt(res.data.token);
        if (
          data.authorities.includes("superAdmin") ||
          data.authorities.includes("admin")
        ) {
          setAdmin(true);
        } else {
          setAdmin(false);
        }
        navigate("/dashboard");
      }
    } catch (e) {
      // handle error
      toast.error("Invalid credentials!");
    } finally {
      setLoader(false);
    }
  };

  const onChangePassword = async () => {
    setLoader(true);
    try {
      // call change password api
      if (newPin !== "" && confirmPin !== "" && newPin === confirmPin) {
        const token = localStorage.getItem("krgr_verify_token");
        const res = await axios.post(
          `https://console-api.theja.in/updatePassword`,
          {
            id: emailNum,
            pin: newPin,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (res.data) {
          localStorage.removeItem("krgr_verify_token");
          localStorage.setItem("krgr_token", token);
          const data = parseJwt(token);
          if (
            data.authorities.includes("superAdmin") ||
            data.authorities.includes("admin")
          ) {
            setAdmin(true);
          } else {
            setAdmin(false);
          }
          navigate("/dashboard");
          switchMode("signin");
        }
      } else {
        toast.error("Passwords do not match!");
      }
    } catch (e) {
      // handle error
      toast.error("Error changing password!");
    } finally {
      setLoader(false);
    }
  };

  const onCreateAccount = () => {
    try {
      // call create account api
      switchMode("signin");
    } catch (e) {
      // handle error
    }
  };

  const onSendOtp = async () => {
    setLoader(true);
    try {
      // call send otp
      const res = await axios.post(
        `https://console-api.theja.in/sendOtp/${emailNum}`
      );
      if (res.data) {
        setOtpSent(true);
      }
    } catch (e) {
      // handle error
      toast.error("Error sending OTP!");
    } finally {
      setLoader(false);
    }
  };

  const onVerifyOtp = async () => {
    setLoader(true);
    try {
      // call verify otp
      const res = await axios.post(
        `https://console-api.theja.in/validateOtp/${emailNum}/${otp}`
      );
      if (res.data) {
        localStorage.setItem("krgr_verify_token", res.data.token);
        setOtpVerified(true);
      }
    } catch (e) {
      // handle error
      toast.error("Error verifying OTP!");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const positions = [];

    for (let i = 0; i < numOfBoxes; i++) {
      positions.push([
        getRandomInt(-750, 1000),
        getRandomInt(-400, 1000),
        getRandomInt(80, 160),
        getRandomInt(0, 9),
      ]);
    }
    setBoxPositions(positions);
  }, []);

  return (
    <div className="bg-gradient-to-r from-[rgb(0,178,137)] to-[rgb(0,133,148)] h-screen w-screen flex flex-col items-center justify-center gap-8 relative overflow-hidden">
      <div>
        <Toaster />
      </div>
      <div className="absolute inset-0">
        <img
          className="w-full h-full"
          src="https://img.freepik.com/premium-vector/abstract-green-blue-blurred-gradient-background-with-metall-texture-light-textured-nature-backdrop-vector-illustration-ecology-concept-your-graphic-design-ui-banner-poster_88211-3210.jpg?w=2000"
          alt="background"
        ></img>
      </div>
      <div className="relative bg-white h-min w-min self-center justify-self-center flex flex-col gap-2 p-12 rounded-md text-gray-600 shadow-2xl z-10">
        {boxPositions.map(([x, y, dimension, anim], index, arr) => {
          let animClass = `move${anim}`;
          return (
            <div
              key={index}
              style={{
                top: `${y}px`,
                left: `${x}px`,
                height: `${dimension}px`,
                width: `${dimension}px`,
              }}
              className={`absolute rounded-lg bg-white/10 border-gray-50/60 border backdrop-blur-sm ${animClass}`}
            ></div>
          );
        })}
        <div className="absolute inset-0 h-full w-full bg-white z-1 rounded-md"></div>
        <div className="absolute h-full w-full z-[-1] bg-black/20 rounded-md top-4 left-4 blur-sm"></div>
        <div className="font-bold text-xl z-10">
          {mode === "signin"
            ? "Login to IoT Console"
            : mode === "forgot"
            ? "Forgot Password"
            : "Create A New Account"}
        </div>
        {mode === "forgot" && (
          <div
            onClick={() => switchMode("signin")}
            className="hover:cursor-pointer absolute top-3 left-3"
          >
            <LeftIcon className="h-4 w-4" />
          </div>
        )}
        {mode === "signin" ? (
          <>
            <div className="flex flex-col gap-1 mt-6 z-10">
              <label
                htmlFor="emailNum"
                className="font-semibold text-gray-600 z-10"
              >
                Registered Mobile/Email
              </label>
              <input
                value={emailNum}
                onChange={(e) => setEmailNum(e.target.value)}
                placeholder="Enter your mobile number or email"
                name="emailNum"
                className="text-sm font-medium w-[300px] px-3 py-2 pl-3 placeholder-black/25 bg-white/5 border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
            </div>
            <div className="flex flex-col gap-1 z-10 mt-6">
              <label
                htmlFor="password"
                className="font-semibold text-gray-600 z-10"
              >
                PIN
              </label>
              <div className="relative">
                <input
                  value={pin}
                  onChange={(e) => {
                    const { value, maxLength } = e.target;
                    const finalValue = value.slice(0, maxLength);
                    setPin(finalValue.replace(/\D/g, ""));
                  }}
                  placeholder="Enter PIN"
                  name="password"
                  className="border-t-0 border-l-0 border-r-0 text-sm font-medium w-[300px] px-3 py-2 pl-3 placeholder-black/25 bg-white/5 border-b-[1px] border-[#E5E7EB] focus:border-b-green-600 focus:outline-none transition-colors z-10 text-black/60 focus:ring-0"
                  maxLength={4}
                  type={showPin ? "text" : "password"}
                ></input>
                <div
                  onClick={() => setShowPin(!showPin)}
                  className="absolute right-0 top-0 cursor-pointer hover:bg-gray-50 p-1 rounded-full"
                >
                  {showPin ? <EyeClosedIcon /> : <EyeIcon />}
                </div>
              </div>
            </div>
            <button
              onClick={() => onLogin()}
              className="flex justify-center items-center w-full p-2 text-white font-semibold rounded-md mt-4 px-8 bg-gradient-to-r from-[rgb(0,178,137)] to-[rgb(5,170,170)] hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-green-600 z-10 transition-all"
            >
              {loader && (
                <svg
                  aria-hidden="true"
                  class="w-4 h-4 mr-2 text-white animate-spin dark:text-gray-600 fill-green-500"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              )}
              <span>Sign In</span>
            </button>
            <div className="flex gap-2 mt-8 text-sm text-gray-400 z-10">
              <div>Forgot password?</div>
              <div
                onClick={() => !loader && switchMode("forgot")}
                className="font-semibold cursor-pointer text-gray-500 z-10"
              >
                Click here
              </div>
            </div>
          </>
        ) : mode === "forgot" ? (
          <>
            <div className="flex flex-col gap-1 mt-6 z-10">
              <label
                htmlFor="emailNum"
                className="font-semibold text-gray-600 z-10"
              >
                Registered Mobile/Email
              </label>
              <input
                value={emailNum}
                disabled={otpSent}
                onChange={(e) => setEmailNum(e.target.value)}
                placeholder="Enter your mobile number or email"
                name="emailNum"
                className="text-sm font-medium w-[300px] px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
            </div>
            {otpSent && (
              <div className="flex flex-col gap-1 z-10 mt-6">
                <label
                  htmlFor="password"
                  className="font-semibold text-gray-600 z-10"
                >
                  OTP
                </label>
                <div className="relative">
                  <input
                    disabled={otpVerified}
                    value={otp}
                    onChange={(e) => {
                      const { value, maxLength } = e.target;
                      const finalValue = value.slice(0, maxLength);
                      setOtp(finalValue.replace(/\D/g, ""));
                    }}
                    placeholder="Enter OTP"
                    name="password"
                    className="disabled:bg-slate-200 disabled:rounded-md pl-3 border-t-0 border-l-0 border-r-0 text-sm font-medium w-[300px] px-3 py-2 placeholder-black/25 bg-white/5 border-b-[1px] border-[#E5E7EB] focus:border-b-green-600 focus:outline-none transition-colors z-10 text-black/60 focus:ring-0"
                    maxLength={4}
                    type={showOtp ? "text" : "password"}
                  ></input>
                  <div
                    onClick={() => setShowOtp(!showOtp)}
                    className="absolute right-0 top-0 cursor-pointer hover:bg-gray-50 p-1 rounded-full"
                  >
                    {showOtp ? <EyeClosedIcon /> : <EyeIcon />}
                  </div>
                </div>
              </div>
            )}
            {otpVerified && (
              <>
                <div className="flex flex-col gap-1 z-10 mt-6">
                  <label
                    htmlFor="password"
                    className="font-semibold text-gray-600 z-10"
                  >
                    New PIN
                  </label>
                  <div className="relative">
                    <input
                      value={newPin}
                      onChange={(e) => {
                        const { value, maxLength } = e.target;
                        const finalValue = value.slice(0, maxLength);
                        setNewPin(finalValue.replace(/\D/g, ""));
                      }}
                      placeholder="Enter New PIN"
                      name="password"
                      className="pl-3 border-t-0 border-l-0 border-r-0 text-sm font-medium w-[300px] px-3 py-2 placeholder-black/25 bg-white/5 border-b-[1px] border-[#E5E7EB] focus:border-b-green-600 focus:outline-none transition-colors z-10 text-black/60 focus:ring-0"
                      maxLength={4}
                      type={showNewPin ? "text" : "password"}
                    ></input>
                    <div
                      onClick={() => setShowNewPin(!showNewPin)}
                      className="absolute right-0 top-0 cursor-pointer hover:bg-gray-50 p-1 rounded-full"
                    >
                      {showNewPin ? <EyeClosedIcon /> : <EyeIcon />}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-1 z-10 mt-6">
                  <label
                    htmlFor="password"
                    className="font-semibold text-gray-600 z-10"
                  >
                    Confirm PIN
                  </label>
                  <div className="relative">
                    <input
                      value={confirmPin}
                      onChange={(e) => {
                        const { value, maxLength } = e.target;
                        const finalValue = value.slice(0, maxLength);
                        setConfirmPin(finalValue.replace(/\D/g, ""));
                      }}
                      placeholder="Confirm New PIN"
                      name="password"
                      className="pl-3 border-t-0 border-l-0 border-r-0 text-sm font-medium w-[300px] px-3 py-2 placeholder-black/25 bg-white/5 border-b-[1px] border-[#E5E7EB] focus:border-b-green-600 focus:outline-none transition-colors z-10 text-black/60 focus:ring-0"
                      maxLength={4}
                      type={showConfirmPin ? "text" : "password"}
                    ></input>
                    <div
                      onClick={() => setShowConfirmPin(!showConfirmPin)}
                      className="absolute right-0 top-0 cursor-pointer hover:bg-gray-50 p-1 rounded-full"
                    >
                      {showConfirmPin ? <EyeClosedIcon /> : <EyeIcon />}
                    </div>
                  </div>
                </div>
              </>
            )}
            <button
              onClick={() =>
                !loader &&
                (otpVerified
                  ? onChangePassword()
                  : otpSent
                  ? onVerifyOtp()
                  : sendOtp
                  ? onSendOtp()
                  : "")
              }
              className="flex justify-center items-center w-full p-2 text-white font-semibold rounded-md mt-4 px-8 bg-gradient-to-r from-[rgb(0,178,137)] to-[rgb(5,170,170)] hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-green-600 z-10 transition-all"
            >
              {loader && (
                <svg
                  aria-hidden="true"
                  class="w-4 h-4 mr-2 text-white animate-spin dark:text-gray-600 fill-green-500"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              )}
              <span>
                {otpVerified
                  ? "Change Password"
                  : otpSent
                  ? "Verify OTP"
                  : sendOtp
                  ? "Send OTP"
                  : ""}
              </span>
            </button>
          </>
        ) : (
          <>
            <div className="flex flex-col gap-1 mt-6 z-10">
              <label
                htmlFor="emailNum"
                className="font-semibold text-gray-600 z-10"
              >
                Username
              </label>
              <input
                value={emailNum}
                onChange={(e) => setEmailNum(e.target.value)}
                placeholder="Enter your username"
                name="emailNum"
                className="text-sm font-medium w-[300px] px-3 py-2 pl-3 placeholder-black/25 bg-white/5 border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
            </div>
            <div className="flex flex-col gap-1 z-10 mt-6">
              <label
                htmlFor="password"
                className="font-semibold text-gray-600 z-10"
              >
                PIN
              </label>
              <div className="relative">
                <input
                  value={pin}
                  onChange={(e) => {
                    const { value, maxLength } = e.target;
                    const finalValue = value.slice(0, maxLength);
                    setPin(finalValue.replace(/\D/g, ""));
                  }}
                  placeholder="Enter PIN"
                  name="password"
                  className="border-t-0 border-l-0 border-r-0 text-sm font-medium w-[300px] px-3 py-2 pl-3 placeholder-black/25 bg-white/5 border-b-[1px] border-[#E5E7EB] focus:border-b-green-600 focus:outline-none transition-colors z-10 text-black/60 focus:ring-0"
                  type={showPin ? "text" : "password"}
                ></input>
                <div
                  onClick={() => setShowPin(!showPin)}
                  className="absolute right-0 top-0 cursor-pointer hover:bg-gray-50 p-1 rounded-full"
                >
                  {showPin ? <EyeClosedIcon /> : <EyeIcon />}
                </div>
              </div>
            </div>
            <button
              onClick={() => onCreateAccount()}
              className="w-full p-2 text-white font-semibold rounded-md mt-4 px-8 bg-gradient-to-r from-[rgb(0,178,137)] to-[rgb(5,170,170)] hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-green-600 z-10 transition-all"
            >
              Sign Up
            </button>
            <div className="flex gap-2 mt-8 text-sm text-gray-400 z-10">
              <div>Already have an account?</div>
              <div
                onClick={() => switchMode("signin")}
                className="font-semibold cursor-pointer text-gray-500 z-10"
              >
                Sign in
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
