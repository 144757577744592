import React from "react";
import { useTable } from "react-table";

const Table = (props) => {
  const { data, columns, totalText, totalValue } = props;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const isTwoColumns = columns.length === 2;

  return (
    <div className="flex-grow flex flex-col w-auto">
      <div className="font-medium mb-2 dark:text-white">{props.title}</div>
      <div className="h-auto w-auto bg-white rounded-md shadow-lg">
        <table {...getTableProps()} className="rounded-md w-full block">
          <thead className="rounded-md block w-full">
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="sticky top-0 flex w-full"
              >
                {headerGroup.headers.map((column, index, arr) => {
                  const isFirstColumn = index === 0;
                  const isLastColumn = arr.length === index + 1;
                  return (
                    <th
                      {...column.getHeaderProps()}
                      className={`w-full block bg-[#4d505e] dark:bg-[#9aa0bb] text-white dark:text-gray-800 text-left p-4 ${
                        isFirstColumn
                          ? "rounded-tl-md"
                          : isLastColumn
                          ? "rounded-tr-md ml-[-10px]"
                          : ""
                      }`}
                    >
                      {column.render("Header")}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="rounded-bl-md rounded-br-md block h-[220px] overflow-auto w-full scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded scrollbar-track-transparent"
          >
            {rows.map((row, index, arr) => {
              const isLastRow = arr.length === index + 1;
              const isEvenRow = index % 2 === 0;
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={`${
                    isLastRow ? "rounded-bl-md rounded-br-md" : ""
                  } flex w-full`}
                >
                  {row.cells.map((cell, index, arr) => {
                    const isFirstCell = index === 0;
                    const isLastCell = arr.length === index + 1;
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`px-4 py-2 ${
                          isEvenRow
                            ? `block ${
                                isTwoColumns ? "w-1/2" : "w-1/3"
                              } bg-white text-gray-700`
                            : `block ${
                                isTwoColumns ? "w-1/2" : "w-1/3"
                              } bg-gray-200 text-gray-900`
                        } ${
                          isLastRow && isFirstCell
                            ? "rounded-bl-md"
                            : isLastRow && isLastCell
                            ? "rounded-br-md"
                            : ""
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="font-medium mt-4 mr-2 dark:text-white self-end">
        {totalText} : {totalValue}
      </div>
    </div>
  );
};

export default Table;
