import React, { useState, useEffect } from "react";
import { formatDateForInput } from "../../utils";
import { useNavigate } from "react-router-dom";
import { Modal } from "flowbite-react";
import { EyeClosedIcon, EyeIcon } from "../Login";
import Calendar from "react-calendar";
import axios from "axios";
import { toast } from "react-hot-toast";
import {DarkIcon,LightIcon} from '../../utils/DarkAndLightIcon'
const Header = ({ darkMode, setDarkMode, isAdmin, userInfo, getInfo }) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const onSignOut = () => {
    try {
      // call login api
      localStorage.clear();
      navigate("/");
    } catch (e) {
      // clear input fields
    }
  };

  const [showAddUser, setShowAddUser] = useState(false);
  const [showMapUser, setShowMapUser] = useState(false);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [pin, setPin] = useState("");
  const [zone, setZone] = useState("");
  const [role, setRole] = useState("");
  const [addr1, setAddr1] = useState("");
  const [addr2, setAddr2] = useState("");
  const [addr3, setAddr3] = useState("");
  const [lang, setLang] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [landmark, setLandmark] = useState("");
  const [addrState, setAddrState] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [showPin, setShowPin] = useState(false);
  const [calendar, setCalendar] = useState(false);
  const [date, setDate] = useState(new Date());
  const [dateString, setDateString] = useState("");

  const [mapUserId, setMapUserId] = useState("");
  const [mapDeviceId, setMapDeviceId] = useState("");

  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [zoneList, setZoneList] = useState([]);

  const initializeAddUserInfo = () => {
    setShowAddUser(false);
    setEmail("");
    setMobile("");
    setPin("");
    setZone("");
    setRole("");
    setAddr1("");
    setAddr2("");
    setAddr3("");
    setLang("");
    setCity("");
    setDistrict("");
    setLandmark("");
    setAddrState("");
    setFirstName("");
    setLastName("");
    setShowPin(false);
    setDate(new Date());
    setDateString("");
  };

  const initializeMapUserInfo = () => {
    setShowMapUser(false);
    setMapUserId("");
    setMapDeviceId("");
  };

  const mapUserWithDevice = async () => {
    setLoader(true);
    try {
      await axios.post(
        `https://console-api.theja.in/admin/mapDevice/${mapDeviceId}/${mapUserId}`,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("krgr_token"),
          },
        }
      );
      await getInfo();
      initializeMapUserInfo();
    } catch (e) {
      // handle error
      toast.error("Error mapping device!");
    } finally {
      setLoader(false);
    }
  };

  const onAddUser = async () => {
    setLoader(true);
    try {
      await axios.post(
        "https://console-api.theja.in/admin/addUser",
        {
          active: true,
          email: email,
          mobile: mobile,
          pin: pin,
          preferredLanguage: lang,
          role: role,
          subscriptionValidity: dateString,
          userDetails: {
            address: {
              addressLine1: addr1,
              addressLine2: addr2,
              addressLine3: addr3,
              city: city,
              district: district,
              landMark: landmark,
              pinCode: pin,
              state: addrState,
            },
            firstName: firstName,
            lastName: lastName,
          },
          zone: zone,
        },
        {
          headers: {
            Authorization: localStorage.getItem("krgr_token"),
          },
        }
      );

      // call add user api
      initializeAddUserInfo();
    } catch (e) {
      // handle error
      toast.error("Error adding user!");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    (async () => {
      const res = await axios.get("https://console-api.theja.in/zone/state", {
        headers: {
          Authorization: localStorage.getItem("krgr_token"),
        },
      });
      setStateList(res.data);
    })();
  }, []);

  useEffect(() => {
    if (addrState) {
      (async () => {
        const res = await axios.get(
          `https://console-api.theja.in/zone/dist/${addrState}`,
          {
            headers: {
              Authorization: localStorage.getItem("krgr_token"),
            },
          }
        );
        setDistrictList(res.data);
      })();
    } else {
      setDistrictList([]);
    }
  }, [addrState]);

  useEffect(() => {
    if (district && addrState) {
      (async () => {
        const res = await axios.get(
          `https://console-api.theja.in/zone/zone/${addrState}/${district}`,
          {
            headers: {
              Authorization: localStorage.getItem("krgr_token"),
            },
          }
        );
        setZoneList(res.data);
      })();
    } else {
      setZoneList([]);
    }
  }, [district, addrState]);

  useEffect(() => {
    setDateString(formatDateForInput(date, "/"));
  }, [date]);

  return (
    <div className="w-full h-max bg-white shadow-md p-4 flex z-100 relative col-start-2 col-end-3 transition-colors dark:bg-slate-700">
      <div className="flex justify-between w-full items-center px-4">
        <div className="ml-8 text-lg text-black/70 dark:text-gray-200 transition-colors">
          Welcome to our IoT Console,{" "}
          <span className="font-semibold">
            {localStorage.getItem('userName') ? localStorage.getItem('userName') : "User"}
          </span>
        </div>
        <div className="flex items-center">
          {!isAdmin ? (
            <>
              <div className="mr-4 text-gray-500 dark:text-gray-200 transition-colors">
                Your subscription is valid until:{" "}
                <span className="font-bold">
                  {userInfo ? userInfo.subscriptionValidity : ""}
                </span>
              </div>
              <a
                href="https://www.google.com"
                target="_blank"
                rel="noreferrer"
                className="p-2 text-white font-semibold rounded-md px-4 bg-gradient-to-r from-[rgb(0,178,137)] to-[rgb(5,170,170)] hover:opacity-80 transition-all focus:outline-none focus:ring-2 focus:ring-green-600 z-10"
              >
                {"Renew Subscription"}
              </a>
            </>
          ) : (
            <>
              <button
                onClick={() => setShowMapUser(true)}
                className="p-2 text-white font-semibold rounded-md px-4 bg-gradient-to-r from-[rgb(0,178,137)] to-[rgb(5,170,170)] hover:opacity-80 transition-all focus:outline-none focus:ring-2 focus:ring-green-600 z-10 mr-4"
              >
                {"Map Devices"}
              </button>
              <button
                onClick={() => setShowAddUser(true)}
                className="p-2 text-white font-semibold rounded-md px-4 bg-gradient-to-r from-[rgb(0,178,137)] to-[rgb(5,170,170)] hover:opacity-80 transition-all focus:outline-none focus:ring-2 focus:ring-green-600 z-10"
              >
                {"Add Users"}
              </button>
            </>
          )}
          <div className="h-full w-[1px] bg-gray-500 dark:bg-gray-200"></div>
          {darkMode ? (
            <div
              title="Light mode"
              onClick={() => {
                localStorage.setItem(
                  "krgr_iot_dark",
                  JSON.stringify({
                    value: false,
                    expiry: new Date().getTime() + 21600000,
                  })
                );
                setDarkMode(!darkMode);
              }}
              className="ml-4 mr-1 text-gray-200 cursor-pointer transition-all hover:bg-slate-600 p-2 rounded-lg"
            >
              <LightIcon />
            </div>
          ) : (
            <div
              title="Dark mode"
              onClick={() => {
                localStorage.setItem(
                  "krgr_iot_dark",
                  JSON.stringify({
                    value: true,
                    expiry: new Date().getTime() + 21600000,
                  })
                );
                setDarkMode(!darkMode);
              }}
              className="ml-4 mr-1 text-gray-500 cursor-pointer transition-all hover:bg-gray-200 p-2 rounded-lg"
            >
              <DarkIcon />
            </div>
          )}
          <button
            onClick={onSignOut}
            className="transition-all text-black/70 dark:text-white/70 dark:hover:bg-slate-800 hover:bg-gray-200 p-2 px-4 rounded-md font-semibold"
          >
            Sign Out
          </button>
        </div>
      </div>
      <Modal
        show={showAddUser}
        size="3xl"
        onClose={() => initializeAddUserInfo()}
      >
        <Modal.Header>
          <div className="text-gray-700 text-xl">Add New User</div>
        </Modal.Header>
        <Modal.Body>
          <div className="h-96 overflow-auto">
            <div className="flex flex-col gap-1 z-10 w-full px-2">
              <label
                htmlFor="mobile"
                className="font-semibold text-gray-600 z-10"
              >
                Mobile Number
              </label>
              <input
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                placeholder="Enter mobile number"
                name="email"
                className="w-full text-sm font-medium px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
            </div>
            <div className="flex flex-col gap-1 mt-6 z-10 w-full px-2">
              <label
                htmlFor="mobile"
                className="font-semibold text-gray-600 z-10"
              >
                First Name
              </label>
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Enter First Name"
                name="email"
                className="w-full text-sm font-medium px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
            </div>
            <div className="flex flex-col gap-1 mt-6 z-10 w-full px-2">
              <label
                htmlFor="mobile"
                className="font-semibold text-gray-600 z-10"
              >
                Last Name
              </label>
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Enter Last Name"
                name="email"
                className="w-full text-sm font-medium px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
            </div>
            <div className="flex flex-col gap-1 z-10 mt-6 px-2">
              <label
                htmlFor="email"
                className="font-semibold text-gray-600 z-10"
              >
                Email
              </label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email"
                name="email"
                className="w-full text-sm font-medium px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
            </div>
            <div className="flex flex-col gap-1 z-10 mt-6 px-2">
              <label
                htmlFor="subscription"
                className="font-semibold text-gray-600 z-10"
              >
                Subscription Valid Till
              </label>
              <input
                readOnly
                value={dateString}
                onBlur={(e) => {
                  if (
                    !e.relatedTarget ||
                    !e.relatedTarget.className ||
                    (e.relatedTarget.className &&
                      !e.relatedTarget.className.includes("react-calendar"))
                  ) {
                    setTimeout(() => setCalendar(false), 100);
                  }
                }}
                onFocus={(e) => {
                  setCalendar(true);
                }}
                className="w-full text-sm font-medium px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
              {calendar && (
                <Calendar
                  onChange={(val) => {
                    setDate(val);
                    setCalendar(false);
                  }}
                  value={date}
                  maxDetail={"month"}
                  minDetail={"decade"}
                />
              )}
            </div>
            <div className="flex flex-col gap-1 z-10 mt-6 px-2">
              <label
                htmlFor="password"
                className="font-semibold text-gray-600 z-10"
              >
                PIN
              </label>
              <div className="relative">
                <input
                  value={pin}
                  onChange={(e) => {
                    const { value, maxLength } = e.target;
                    const finalValue = value.slice(0, maxLength);
                    setPin(finalValue.replace(/\D/g, ""));
                  }}
                  placeholder="Enter PIN"
                  name="password"
                  className="w-full disabled:bg-slate-200 disabled:rounded-md pl-3 border-t-0 border-l-0 border-r-0 text-sm font-medium px-3 py-2 placeholder-black/25 bg-white/5 border-b-[1px] border-[#E5E7EB] focus:border-b-green-600 focus:outline-none transition-colors z-10 text-black/60 focus:ring-0"
                  maxLength={4}
                  type={showPin ? "text" : "password"}
                ></input>
                <div
                  onClick={() => setShowPin(!showPin)}
                  className="absolute right-0 top-0 cursor-pointer hover:bg-gray-50 p-1 rounded-full"
                >
                  {showPin ? <EyeClosedIcon /> : <EyeIcon />}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-1 mt-6 z-10 w-full px-2">
              <label
                htmlFor="mobile"
                className="font-semibold text-gray-600 z-10"
              >
                Preferred Language
              </label>
              <input
                value={lang}
                onChange={(e) => setLang(e.target.value)}
                placeholder="Enter Preferred Language"
                name="email"
                className="w-full text-sm font-medium px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
            </div>
            <div className="flex flex-col gap-1 mt-6 z-10 w-full px-2">
              <label
                htmlFor="mobile"
                className="font-semibold text-gray-600 z-10"
              >
                Role
              </label>
              <input
                value={role}
                onChange={(e) => setRole(e.target.value)}
                placeholder="Enter Role"
                name="email"
                className="w-full text-sm font-medium px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
            </div>
            <div className="flex flex-col gap-1 mt-6 z-10 w-full px-2">
              <label
                htmlFor="mobile"
                className="font-semibold text-gray-600 z-10"
              >
                Address Line 1
              </label>
              <input
                value={addr1}
                onChange={(e) => setAddr1(e.target.value)}
                placeholder="Enter Address Line 1"
                name="email"
                className="w-full text-sm font-medium px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
            </div>
            <div className="flex flex-col gap-1 mt-6 z-10 w-full px-2">
              <label
                htmlFor="mobile"
                className="font-semibold text-gray-600 z-10"
              >
                Address Line 2
              </label>
              <input
                value={addr2}
                onChange={(e) => setAddr2(e.target.value)}
                placeholder="Enter Address Line 2"
                name="email"
                className="w-full text-sm font-medium px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
            </div>
            <div className="flex flex-col gap-1 mt-6 z-10 w-full px-2">
              <label
                htmlFor="mobile"
                className="font-semibold text-gray-600 z-10"
              >
                Address Line 3
              </label>
              <input
                value={addr3}
                onChange={(e) => setAddr3(e.target.value)}
                placeholder="Enter Address Line 3"
                name="email"
                className="w-full text-sm font-medium px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
            </div>
            <div className="flex">
              <div className="w-1/2 flex flex-col gap-1 z-10 mt-6 px-2">
                <label
                  htmlFor="zone"
                  className="font-semibold text-gray-600 z-10"
                >
                  State
                </label>
                <select
                  value={addrState}
                  onChange={(e) => setAddrState(e.target.value)}
                  placeholder="Enter State"
                  name="zone"
                  className="w-full border-b-[#e5e7eb] text-sm font-medium px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors border-t-0 border-l-0 border-r-0 focus:shadow-none focus:ring-0"
                >
                  <option key="" value="" style={{ display: "none" }}></option>
                  {stateList.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-1/2 flex flex-col gap-1 z-10 mt-6 px-2">
                <label
                  htmlFor="role"
                  className="font-semibold text-gray-600 z-10"
                >
                  District
                </label>
                <select
                  defaultValue={""}
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                  placeholder="Enter role"
                  name="role"
                  className="w-full border-b-[#e5e7eb] text-sm font-medium px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors border-t-0 border-l-0 border-r-0 focus:shadow-none focus:ring-0"
                >
                  <option key="" value="" style={{ display: "none" }}></option>
                  {districtList.map((district) => (
                    <option key={district} value={district}>
                      {district}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex">
              <div className="w-full flex flex-col gap-1 z-10 mt-6 px-2">
                <label
                  htmlFor="zone"
                  className="font-semibold text-gray-600 z-10"
                >
                  Zone
                </label>
                <select
                  value={zone}
                  onChange={(e) => setZone(e.target.value)}
                  placeholder="Enter zone"
                  name="zone"
                  className="w-full border-b-[#e5e7eb] text-sm font-medium px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors border-t-0 border-l-0 border-r-0 focus:shadow-none focus:ring-0"
                >
                  {zoneList.map((zone) => (
                    <option key={zone} value={zone}>
                      {zone}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex flex-col gap-1 mt-6 z-10 w-full px-2">
              <label
                htmlFor="mobile"
                className="font-semibold text-gray-600 z-10"
              >
                City
              </label>
              <input
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="Enter City"
                name="email"
                className="w-full text-sm font-medium px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
            </div>
            <div className="flex flex-col gap-1 mt-6 z-10 w-full px-2">
              <label
                htmlFor="mobile"
                className="font-semibold text-gray-600 z-10"
              >
                Landmark
              </label>
              <input
                value={landmark}
                onChange={(e) => setLandmark(e.target.value)}
                placeholder="Enter Landmark"
                name="email"
                className="w-full text-sm font-medium px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end w-full">
            <button
              onClick={() => onAddUser()}
              className="flex justify-center items-center p-2 text-white font-semibold rounded-md px-4 bg-gradient-to-r from-[rgb(0,178,137)] to-[rgb(5,170,170)] hover:opacity-80 transition-all focus:outline-none focus:ring-2 focus:ring-green-600 z-10"
            >
              {loader && (
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 mr-2 text-white animate-spin dark:text-gray-600 fill-green-500"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              )}
              <span>Add User</span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showMapUser}
        size="md"
        onClose={() => initializeMapUserInfo()}
      >
        <Modal.Header>
          <div className="text-gray-700 text-xl">Map User with Device</div>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="flex flex-col gap-1 z-10 px-2">
              <label
                htmlFor="userid"
                className="font-semibold text-gray-600 z-10"
              >
                User Mobile Number
              </label>
              <input
                value={mapUserId}
                onChange={(e) => setMapUserId(e.target.value)}
                placeholder="Enter user's mobile number"
                name="userid"
                className="w-full text-sm font-medium px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
            </div>
            <div className="flex flex-col gap-1 z-10 mt-6 px-2">
              <label
                htmlFor="deviceid"
                className="font-semibold text-gray-600 z-10"
              >
                Device ID
              </label>
              <input
                value={mapDeviceId}
                onChange={(e) => setMapDeviceId(e.target.value)}
                placeholder="Enter device id"
                name="deviceid"
                className="w-full text-sm font-medium px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end w-full">
            <button
              onClick={() => mapUserWithDevice()}
              className="flex justify-center items-center p-2 text-white font-semibold rounded-md px-4 bg-gradient-to-r from-[rgb(0,178,137)] to-[rgb(5,170,170)] hover:opacity-80 transition-all focus:outline-none focus:ring-2 focus:ring-green-600 z-10"
            >
              {loader && (
                <svg
                  aria-hidden="true"
                  class="w-4 h-4 mr-2 text-white animate-spin dark:text-gray-600 fill-green-500"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              )}
              <span>Map Device</span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Header;
