import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import Table from "./Table";
import  {formatDateForInput, formatDateForLogsApi } from "../../utils";
import Motor from "../../assets/motor.jpeg";
import Power from "../../assets/power.png";
import Refresh from "../../assets/refresh.png";
import axios from "axios";
import { useCallback } from "react";
import { Spinner } from "../../utils/DarkAndLightIcon";

const DeviceInfo = (props) => {
  const { selectedDevice, refetchLogs, fetchLoader } = props;

  const [calendar, setCalendar] = useState(false);
  const [date, setDate] = useState(new Date());
  const [dateString, setDateString] = useState("");
  const [switchState, setSwitchState] = useState(true);
  const [neutralSwitch, setNeutralSwitch] = useState(false);

  const [powerData, setPowerData] = useState([]);
  const [powerColumns, setPowerColumns] = useState([]);
  const [powerTotal, setPowerTotal] = useState("");
  const [motorData, setMotorData] = useState([]);
  const [motorColumns, setMotorColumns] = useState([]);
  const [motorTotal, setMotorTotal] = useState("");
  const [loadData, setLoadData] = useState([]);
  const [loadColumns, setLoadColumns] = useState([]);
  const [loadTotal, setLoadTotal] = useState("");
  
  useEffect(() => {
    setDateString(formatDateForInput(date, "/"));
  }, [date]);

  const showLoader = fetchLoader && !selectedDevice;

  const onSwitchToggle = async (e) => {
    if (neutralSwitch) {
      return;
    }
    setNeutralSwitch(true);
    try {
      await axios.get(
        `https://console-api.theja.in/motor/${
          e.target.checked ? "onCommand" : "offCommand"
        }/${selectedDevice.deviceId}`,
        {
          headers: {
            Authorization: localStorage.getItem("krgr_token"),
          },
        }
      );
      setNeutralSwitch(false);
      setSwitchState(!e.target.checked);
    } catch (e) {
      //
    }
  };

  const onClass =
    "border-2 border-green-900 text-emerald-900 font-semibold bg-gradient-to-r from-[#36ff72] to-[#7dffa7]";
  const offClass =
    "border-2 border-red-900 text-orange-900 font-semibold bg-gradient-to-r from-[#ff7474] to-[#ffa790]";
  const neutralClass =
    "border-2 border-gray-900 text-gray-700 font-semibold bg-gradient-to-r from-[#aaaaaa] to-[#dedede]";

  const {
    deviceState = false,
    givenState = false,
    powerAvailable = false,
    lastDeviceState = "",
    lastGivenState = "",
    powerLastPing = "",
    deviceId = "",
  } = selectedDevice || {};

  useEffect(() => {
    setSwitchState(givenState);
  }, [givenState]);

  const onLogDateChange = (value) => {
    setDate(value);
    setCalendar(false);
  };

  const computeTableColumns = (logsResponse) => {
    const { loadLogsDto, motorLogsDto, powerLogsDto } = logsResponse;

    const powerMotorColumns = [
      {
        Header: "ON",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "OFF",
        accessor: "col2",
      },
      {
        Header: "Duration",
        accessor: "col3",
      },
    ];

    const loadColumns = [
      {
        Header: "Current (Avg)",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "Voltage (Avg)",
        accessor: "col2",
      },
    ];

    setPowerColumns(powerMotorColumns);
    setMotorColumns(powerMotorColumns);
    setLoadColumns(loadColumns);

    setPowerTotal(
      powerLogsDto ? powerLogsDto.totalTime || "-no-data-" : "-no-data-"
    );
    setMotorTotal(
      motorLogsDto ? motorLogsDto.totalTime || "-no-data-" : "-no-data-"
    );
    setLoadTotal(
      loadLogsDto ? loadLogsDto.totalRecords || "-no-data-" : "-no-data-"
    );

    const powerData = [];
    powerLogsDto && powerLogsDto.onOffLogDtos.length
      ? powerLogsDto.onOffLogDtos.forEach((record) => {
          powerData.push({
            col1: record.onTime || "-",
            col2: record.offTime || "-",
            col3: record.difference ? record.difference.valueForRollup : "-",
          });
        })
      : powerData.push({
          col1: "-",
          col2: "-",
          col3: "-",
        });

    const motorData = [];
    motorLogsDto && motorLogsDto.onOffLogDtos.length
      ? motorLogsDto.onOffLogDtos.forEach((record) => {
          motorData.push({
            col1: record.onTime || "-",
            col2: record.offTime || "-",
            col3: record.difference ? record.difference.valueForRollup : "-",
          });
        })
      : motorData.push({
          col1: "-",
          col2: "-",
          col3: "-",
        });

    const loadData = [];
    loadLogsDto
      ? loadData.push({
          col1: loadLogsDto.avgCurrent || "-",
          col2: loadLogsDto.avgVoltage || "-",
        })
      : loadData.push({
          col1: "-",
          col2: "-",
        });

    setPowerData(powerData);
    setMotorData(motorData);
    setLoadData(loadData);
  };

  const fetchLogs = useCallback(async () => {
    const dateString = formatDateForLogsApi(date);
    try {
      if (deviceId) {
        const res = await axios.get(
          `https://console-api.theja.in/getLogs/${deviceId}/${dateString}`,
          {
            headers: {
              Authorization: localStorage.getItem("krgr_token"),
            },
          }
        );
        computeTableColumns(res.data);
      }
    } catch (e) {
      //
    }
  }, [date, deviceId, refetchLogs]);

  useEffect(() => {
    fetchLogs();
  }, [fetchLogs]);

  return (
    <div className="h-full flex-grow p-7 bg-gray-50 dark:bg-slate-700 pt-2 flex flex-col gap-8 
    overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded 
    scrollbar-track-transparent">
      {!showLoader ? (
        selectedDevice ? (
          <>
            <div className="text-2xl text-black dark:text-white font-bold text-center mt-8">
              {selectedDevice.name}
            </div>
            <div className="flex gap-8">
              <div
                className={`rounded-md flex-grow p-8 ${
                  powerAvailable ? onClass : offClass
                }`}
              >
                <div className="flex justify-between">
                  <div>
                    <div className="font-semibold text-xl">Power Status</div>
                    <div className="font-bold text-2xl mt-2">
                      {powerAvailable ? "ON" : "OFF"}
                    </div>
                  </div>
                  <img className="h-32" src={Power} alt="power-icon" />
                </div>
                <div>
                  Last {!powerAvailable ? "On" : "Off"} :{" "}
                  {powerLastPing || "No Data"}
                </div>
              </div>
              <div
                className={`rounded-md flex-grow p-8 ${
                  deviceState ? onClass : offClass
                }`}
              >
                <div className="flex justify-between">
                  <div>
                    <div className="font-semibold text-xl">Motor Status</div>
                    <div className="font-bold text-2xl mt-2">
                      {deviceState ? "ON" : "OFF"}
                    </div>
                  </div>
                  <img className="h-32" src={Motor} alt="motor-icon" />
                </div>
                <div>
                  Last {!deviceState ? "On" : "Off"} :{" "}
                  {lastDeviceState || "No Data"}
                </div>
              </div>
              <div
                className={`rounded-md transition-all flex-grow p-8 ${
                  neutralSwitch
                    ? neutralClass
                    : switchState
                    ? onClass
                    : offClass
                }`}
              >
                <div className="flex justify-between">
                  <div>
                    <div className="font-semibold text-xl">Motor Switch</div>
                    <div className="font-bold text-2xl mt-2">
                      {switchState ? "ON" : "OFF"}
                    </div>
                  </div>
                  <div className="flex items-center h-32">
                    <label
                      htmlFor="large-toggle"
                      className="inline-flex relative items-center cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        onChange={(e) => onSwitchToggle(e)}
                        id="large-toggle"
                        className="sr-only peer"
                        checked={switchState}
                      />
                      <div
                        className={`w-14 h-[32px] ${
                          neutralSwitch
                            ? "bg-gray-400 peer-checked:bg-gray-400 after:bg-gray-100 peer-checked:after:bg-gray-100"
                            : "bg-red-400 peer-checked:bg-green-400 after:bg-red-100 peer-checked:after:bg-green-100"
                        } peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-1 after:left-[4px] after:rounded-full after:h-6 after:w-6 after:transition-all`}
                      ></div>
                    </label>
                  </div>
                </div>
                <div>
                  Last {!switchState ? "On" : "Off"} :{" "}
                  {lastGivenState || "No Data"}
                </div>
              </div>
            </div>
            <div className="h-[2px] bg-gray-100 dark:bg-slate-400"></div>
            <div className="relative">
              <span className="mr-4 dark:text-white font-semibold">
                View Logs:
              </span>
              <input
                readOnly
                onBlur={(e) => {
                  if (
                    !e.relatedTarget ||
                    !e.relatedTarget.className ||
                    (e.relatedTarget.className &&
                      !e.relatedTarget.className.includes("react-calendar"))
                  ) {
                    setTimeout(() => setCalendar(false), 100);
                  }
                }}
                value={dateString}
                onFocus={(e) => {
                  setCalendar(true);
                }}
                className="ring-2 ring-black/10 text-black/60 rounded-md p-2 px-4 focus:outline-none focus:ring-2 focus:ring-black/30"
              ></input>
              {calendar && (
                <Calendar
                  onChange={(val) => {
                    onLogDateChange(val);
                  }}
                  value={date}
                  maxDetail={"month"}
                  minDetail={"decade"}
                />
              )}
              <span className="cursor-pointer" onClick={() => fetchLogs()}>
                <img alt="refresh" className="ml-4 h-6 inline" src={Refresh} />
              </span>
            </div>
            <div className="flex gap-4">
              <Table
                title={"Power logs"}
                data={powerData}
                columns={powerColumns}
                totalText="Total Duration"
                totalValue={powerTotal}
              />
              <Table
                title={"Motor logs"}
                data={motorData}
                columns={motorColumns}
                totalText="Total Duration"
                totalValue={motorTotal}
              />
              <Table
                title={"Load logs"}
                data={loadData}
                columns={loadColumns}
                totalText="Total Records"
                totalValue={loadTotal}
              />
            </div>
          </>
        ) : (
          <div className="w-full h-full flex justify-center mt-16">
            No devices available for this user!
          </div>
        )
      ) : (
        <div className="h-full w-full flex justify-center items-center">
          <Spinner />
        </div>
      )}
      
    </div>
  );
};

export default DeviceInfo;
