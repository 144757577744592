import React, { useEffect, useState } from "react";
import { Modal } from "flowbite-react";
import Logo from "../../assets/logo.png";
import LogoDark from "../../assets/logobw.png";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import Calendar from "react-calendar";
import { formatDateForInput } from "../../utils";
import { useNavigate } from "react-router-dom";

const Devices =({
  selectedDevice,
  setSelectedDevice,
  isAdmin,
  darkMode,
  deviceList,
  userInfo,
}) => {
  const onlineClass =
    "text-[#29bf6c] bg-gradient-to-l from-[#deffed] to-[#c4ffde] font-semibold";
  const offlineClass =
    "text-[#bf2929] bg-gradient-to-l from-[#ffdede] to-[#fccbcb] font-semibold";

  const onlineSelectedClass =
    "text-[#29bf6c] bg-gradient-to-l from-[#deffed] to-[#c4ffde] font-semibold";
  const offlineSelectedClass =
    "text-[#bf2929] bg-gradient-to-l from-[#ffdede] to-[#fccbcb] font-semibold";

  const onlineStatusClass =
    "border-2 border-[#3BB51F] text-[#3BB51F] font-semibold online-twitch";
  const offlineStatusClass =
    "border-2 border-[#d22c32] text-[#d22c32] font-semibold offline-twitch";

  const onDeviceClick = (device) => {
    setSelectedDevice(device);
  };

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (!selectedDevice && deviceList && deviceList.length) {
      setSelectedDevice(deviceList[0]);
    }
  }, [selectedDevice, deviceList, setSelectedDevice]);

  const navigate=useNavigate();

  const [showAddDevice, setShowAddDevice] = useState(false);
  const [deviceId, setDeviceId] = useState("");
  const [deviceSerial, setDeviceSerial] = useState("");
  const [name, setName] = useState("");
  const [deviceModel, setDeviceModel] = useState("");
  const [calendar, setCalendar] = useState(false);
  const [date, setDate] = useState(new Date());
  const [subscriptionValidity, setSubscriptionValidity] = useState("");

  const initializeAddDeviceInfo = () => {
    setShowAddDevice(false);
    setDeviceId("");
    setDeviceSerial("");
    setName("");
    setDeviceModel("");
    setDate(new Date());
    setSubscriptionValidity("");
  };
  useEffect(() => {
    setSubscriptionValidity(formatDateForInput(date, "/"));
  }, [date]);

  const onAddDevice = async () => {
    setLoader(true);
    try {
      await axios.post(
        "https://console-api.theja.in/admin/addDeviceV2",
        {
          active: "true",
          deviceId: deviceId,
          deviceSerialNumber: deviceSerial,
          name: name,
          deviceModel: deviceModel,
          subscriptionValidity: subscriptionValidity,
        },
        {
          headers: {
            Authorization: localStorage.getItem("krgr_token"),
          },
        }
      );
      initializeAddDeviceInfo();
    } catch (e) {
      // handle error
      toast.error("Error adding device!");
    } finally {
      setLoader(false);
    }
  };
  
  // navigate ManageUser
  const manageUserHandler= async () =>{
    navigate('/manageUsers',{state:{isAdmin:isAdmin}}) 
  }
  // navigate manageDevice
  const ManageDeviceHandler= async () =>{
    navigate('/manageDevice',{state:{isAdmin:isAdmin}})  
  }

  return (
    <div className="h-full row-span-2 bg-white dark:bg-slate-800 overflow-auto scrollbar-thin
     scrollbar-thumb-gray-300 scrollbar-thumb-rounded scrollbar-track-transparent">
      <div>
        <Toaster />
      </div>
      <div className="m-6">
        <div className="p-2 w-full bg-white rounded-md mb-6">
          <img src={darkMode ? LogoDark : Logo} alt="krgr-logo"></img>
        </div>
      </div>
      <div style={{ height: "calc(100% - 300px)" }}>
     {isAdmin &&
      <div className={`flex flex-row w-full gap-2 p-6 pt-0 pb-0 text-sm`}>
      <div
          onClick={()=>manageUserHandler()}
          className={`text-black w-full mb-1 dark:text-white transition-colors 
          text-center font-semibold dark:bg-slate-700 bg-gray-100 
          border-2 dark:border-2 dark:border-slate-700 p-2 rounded-md hover:cursor-pointer
          `}
        >
        Manage Users
        </div>
        <div
          onClick={()=> ManageDeviceHandler()}
          className={`text-black w-full mb-1 dark:text-white transition-colors 
          text-center font-semibold dark:bg-slate-700 bg-gray-100 
          border-2 dark:border-2 dark:border-slate-700 p-2 rounded-md hover:cursor-pointer`}
        >
        Manage Devices
        </div>
      </div>
      }
        <div
          onClick={() => isAdmin && setShowAddDevice(true)}
          className={`text-black mb-5 dark:text-white transition-colors
           text-center font-semibold dark:bg-slate-700 bg-gray-100 border-2 
           dark:border-2 dark:border-slate-700 p-2 m-6 mt-4 rounded-md ${
            isAdmin && "hover:cursor-pointer"
          }`}
        >
          {isAdmin ? "Add Device" : "Your Devices"}
        </div>
        <div
          style={{ height: "calc(100% - 40px)" }}
          className="px-8 flex items-center gap-8 flex-col overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded scrollbar-track-transparent"
        >
          {deviceList && deviceList.length ? (
            deviceList.map((device) => {
              const isDeviceSelected = selectedDevice
                ? selectedDevice.deviceId === device.deviceId
                : false;
              return (
                <div
                  key={device.deviceId}
                  onClick={() => onDeviceClick(device)}
                  className={`${
                    isDeviceSelected
                      ? device.powerAvailable
                        ? onlineStatusClass
                        : offlineStatusClass
                      : "font-semibold border-2 dark:border-slate-500 shadow-gray-200/30 dark:shadow-slate-100/10 bg-white dark:bg-slate-600 text-gray-600 dark:text-slate-300"
                  } cursor-pointer shadow-lg flex flex-col gap-2 w-full rounded-lg relative top-0 hover:top-1 transition-all`}
                >
                  <span className="flex justify-between mx-4 mt-4">
                    <span className="mr-4">DeviceId: </span>
                    <span className="text-right">{device.deviceId}</span>
                  </span>
                  <span className="flex justify-between mx-4">
                    <span className="mr-4">DeviceName: </span>
                    <span className="text-right">{device.name}</span>
                  </span>
                  <span className="flex justify-between ml-4 mb-4">
                    <span className="mr-4">Status: </span>
                    <div
                      className={`${
                        device.powerAvailable
                          ? isDeviceSelected
                            ? onlineSelectedClass
                            : onlineClass
                          : isDeviceSelected
                          ? offlineSelectedClass
                          : offlineClass
                      } text-sm mt-1 py-1 flex items-center w-max rounded-l-full px-4`}
                    >
                      {device.powerAvailable ? "Online" : "Offline"}
                    </div>
                  </span>
                </div>
              );
            })
          ) : (
            <div>No devices available!</div>
          )}
        </div>
      </div>
      {/* Add Device */}
      <Modal
        show={showAddDevice}
        size="3xl"
        onClose={() => setShowAddDevice(false)}
      >
        <Modal.Header>
          <div className="text-gray-700 text-xl">Add New Device</div>
        </Modal.Header>
        <Modal.Body>
          <div className="h-96 overflow-auto scrollbar">
            <div className="flex flex-col gap-1 z-10 w-full px-2 mt-6">
              <label
                htmlFor="deviceId"
                className="font-semibold text-gray-600 z-10"
              >
                Device ID
              </label>
              <input
                value={deviceId}
                onChange={(e) => setDeviceId(e.target.value)}
                placeholder="Enter Device ID"
                name="deviceId"
                className="w-full text-sm font-medium px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
            </div>
            <div className="flex flex-col gap-1 z-10 w-full px-2">
              <label
                htmlFor="name"
                className="font-semibold text-gray-600 z-10"
              >
                Name
              </label>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Name"
                name="name"
                className="w-full text-sm font-medium px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
            </div>

            <div className="flex flex-col gap-1 z-10 w-full px-2 mt-6">
              <label
                htmlFor="device serial number"
                className="font-semibold text-gray-600 z-10"
              >
                Device Serial Number
              </label>
              <input
                value={deviceSerial}
                onChange={(e) => setDeviceSerial(e.target.value)}
                placeholder="Enter Device Serial Number"
                name="device serial number"
                className="w-full text-sm font-medium px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
            </div>

            <div className="flex flex-col gap-1 z-10 w-full px-2 mt-6">
              <label
                htmlFor="deviceModel"
                className="font-semibold text-gray-600 z-10"
              >
                Device Model
              </label>
              <input
                value={deviceModel}
                onChange={(e) => setDeviceModel(e.target.value)}
                placeholder="Enter your Device Model"
                name="deviceModel"
                className="w-full text-sm font-medium px-3 py-2 pl-3 placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
            </div>

            <div className="flex flex-col gap-1 z-10 mt-6 px-2">
              <label
                htmlFor="subscription Validity"
                className="font-semibold text-gray-600 z-10"
              >
                Subscription Validity
              </label>
              <input
                readOnly
                placeholder="Select Validity Date"
                value={subscriptionValidity}
                onBlur={(e) => {
                  if (
                    !e.relatedTarget ||
                    !e.relatedTarget.className ||
                    (e.relatedTarget.className &&
                      !e.relatedTarget.className.includes("react-calendar"))
                  ) {
                    setTimeout(() => setCalendar(false), 100);
                  }
                }}
                onFocus={(e) => {
                  setCalendar(true);
                }}
                className="w-full text-sm font-medium px-3 py-2 pl-3 cursor-pointer placeholder-black/25 bg-white/5 disabled:bg-slate-200 disabled:rounded-md border-b-[1px] focus:border-b-[1px] focus:border-b-green-600 focus:outline-none z-10 text-black/60 transition-colors"
              ></input>
              {calendar && (
                <Calendar
                  onChange={(val) => {
                    setDate(val);
                    setCalendar(false);
                  }}
                  value={date}
                  maxDetail={"month"}
                  minDetail={"decade"}
                />
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end w-full">
            <button
              onClick={() => onAddDevice()}
              className="flex justify-center items-center p-2 text-white font-semibold rounded-md px-4 bg-gradient-to-r from-[rgb(0,178,137)] to-[rgb(5,170,170)] hover:opacity-80 transition-all focus:outline-none focus:ring-2 focus:ring-green-600 z-10"
            >
              {loader && (
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 mr-2 text-white animate-spin dark:text-gray-600 fill-green-500"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              )}
              <span>Add Device</span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      
    </div>
  );
};

export default Devices;
