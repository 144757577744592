
// used in manage device
export const DateFormatForInput=(date)=>{
    let newFo=date.split("/");
      let year=newFo[0];
      let month=newFo[1];
      let day=newFo[2];
      let newFormat=day+'-'+month+'-'+year
      return newFormat;
    }
    export const DateFormatForApi=(date)=>{
        let newFo=date.split("-");
          let year=newFo[2];
          let month=newFo[1];
          let day=newFo[0];
          let newFormat=year+'/'+month+'/'+day
          return newFormat;
        }
