import React, { useEffect, useState, useCallback, useContext } from "react";
import Header from "./components/Header";
import Content from "./components/Content";
import DeviceInfo from "./components/DeviceInfo";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { parseJwt } from "../utils";
import { toast } from "react-hot-toast";
import { Context } from "../App";

const Dashboard = ({ isAdmin, setAdmin}) => {
  const [darkMode,setDarkMode]=useContext(Context)
  const [fetchLoader, setFetchLoader] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [deviceList, setDeviceList] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  const [refetchLogs, setRefetchLogs] = useState(false);

  const authToken = localStorage.getItem("krgr_token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    } else {
      const data = parseJwt(authToken);
      if (
        data.authorities.includes("superAdmin") ||
        data.authorities.includes("admin")
      ) {
        setAdmin(true)
      } else {
        setAdmin(false);
      }
    }
  }, [authToken, navigate, setAdmin]);

  const getInfo = useCallback(async () => {
    setFetchLoader(true);
    try {
      const res = await axios.get("https://console-api.theja.in/user/getInfo", {
        headers: {
          Authorization: authToken,
        },
      });
      localStorage.setItem("userName",res.data.name)
      const { deviceIds, ...userDetails } = res.data;
      setUserInfo(userDetails);

      const devices = res.data.deviceIds || [];
      const sortedDevices = devices.sort(
        (prev, curr) => parseInt(prev) - parseInt(curr)
      );
      const devicesInfo = [];

      for (const device of sortedDevices) {
        const deviceInfo = await axios.get(
          `https://console-api.theja.in/motor/get/${device}`,
          {
            headers: {
              Authorization: authToken,
            },
          }
        );
        devicesInfo.push(deviceInfo.data);
        setSelectedDevice((selectedDevice) => {
          const selectedDeviceId = selectedDevice
            ? selectedDevice.deviceId
            : "";
          if (selectedDevice && selectedDeviceId === device) {
            return deviceInfo.data;
          }
          return selectedDevice;
        });
      }
      setDeviceList(devicesInfo);
      setRefetchLogs((value) => !value);
    } catch (e) {
      // handle error
      toast.error("Error fetching data for this device!");
    } finally {
      setFetchLoader(false);
    }
  }, [authToken]);

  useEffect(() => {
    getInfo();
    const interval = setInterval(getInfo, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [getInfo]);

  useEffect(() => {
    let manualDarkMode = localStorage.getItem("krgr_iot_dark")
      ? JSON.parse(localStorage.getItem("krgr_iot_dark"))
      : null;

    // deprecating support older dark mode config, remove this after 2months
    if (typeof manualDarkMode === "string") {
      manualDarkMode = null;
    }

    if (manualDarkMode && manualDarkMode.expiry > new Date().getTime()) {
      setDarkMode(manualDarkMode.value);
    } else {
      const currentHour = new Date().getHours();
      const darkHours = [6,7, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5, 6, 7];
      const darkMode = darkHours.includes(currentHour);
      setDarkMode(darkMode);
    }
  });

  return (
    <div
      className={`h-full w-full bg-gray-100 grid grid-rows-dashboard 
      grid-cols-dashboard transition-colors ${
        darkMode ? "darkModeEnabled" : ""
      }`}
    >
      <Content
        userInfo={userInfo}
        deviceList={deviceList}
        selectedDevice={selectedDevice}
        setSelectedDevice={setSelectedDevice}
        darkMode={darkMode}
        isAdmin={isAdmin}
      />
      <Header
        userInfo={userInfo}
        darkMode={darkMode}
        setDarkMode={setDarkMode}
        isAdmin={isAdmin}
        getInfo={getInfo}
      />
      <DeviceInfo
        selectedDevice={selectedDevice}
        refetchLogs={refetchLogs}
        fetchLoader={fetchLoader}
      />  
    </div>
  );
};

export default Dashboard;
