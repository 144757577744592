import React from "react";
import Devices from "./Devices";

const Content = ({
  isAdmin,
  darkMode,
  selectedDevice,
  setSelectedDevice,
  deviceList,
  userInfo,
}) => {
  return (
    <div className="flex h-full overflow-auto bg-white row-span-2 dark:bg-slate-900" id="scrollbar-hide">
      <Devices
        darkMode={darkMode}
        selectedDevice={selectedDevice}
        deviceList={deviceList}
        userInfo={userInfo}
        setSelectedDevice={setSelectedDevice}
        isAdmin={isAdmin}
      />
    </div>
  );
};

export default Content;
