import './App.css';
import { HashRouter, Routes, Route } from 'react-router-dom'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import 'flowbite';
import React, { useState } from 'react'
import ManageDevices from './pages/components/ManageDevices';
import ManageUsers from './pages/components/ManageUsers';

export const Context=React.createContext();  
function App() {

  const [isAdmin, setAdmin] = useState(false)
  const [darkMode,setDarkMode]= useState(false)

  return (
    <Context.Provider  value={[darkMode,setDarkMode]}>
    <HashRouter>
      <Routes>
        <Route path="/" element={<Login isAdmin={isAdmin} setAdmin={setAdmin}/>}></Route>
        <Route path="/dashboard" element={<Dashboard isAdmin={isAdmin} setAdmin={setAdmin}/>}></Route>
        <Route path="/manageDevice" element={<ManageDevices />}></Route>
        <Route path="/manageUsers" element={<ManageUsers />}></Route>

      </Routes>
    </HashRouter>
    </Context.Provider>
  );
}

export default App;
