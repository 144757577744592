import React, { useContext, useEffect, useState } from "react";
import Logo from "../../assets/logo.png";
import LogoDark from "../../assets/logobw.png";
import HomeIcon from "@mui/icons-material/Home";
import { useLocation, useNavigate } from "react-router-dom";
import { DarkIcon, LightIcon, Spinner } from "../../utils/DarkAndLightIcon";
import { Context } from "../../App";
import { toast } from "react-hot-toast";
import axios from "axios";
import { DateFormatForApi, DateFormatForInput } from "../../utils/Dateformat";
function ManageDevices() {
  const [darkMode, setDarkMode] = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState("");
  const [device, setDevice] = useState([]);
  const [deviceIds, setDeviceIds] = useState([]);
  const [accountIds, setAccountIds] = useState([]);
  const [edit, setEdit] = useState(false);
  const [toggle, setToggle] = useState();
  const [meta, setMeta] = useState([{ key: "", value: "" }]);
  const [meta1, setMeta1] = useState([{ key: "", value: "" }]);
  const isAdmin = location.state.isAdmin;
  const [loader, setloader] = useState(false);

  const initialiser = () => {
    setDevice("");
    setAccountIds("");
    setMeta("");
    setMeta1("");
    setSearch("");
    setEdit(false);
    setloader(true);
  };
  const dataInitialiser = (res) => {
    setDevice(res.data);
    if (res.data.meta !== null && res.data.meta !== "") {
      const valuesObjKey = [];
      Object.keys(res.data.meta).forEach((item) => {
        if (item.length > 1)
          valuesObjKey.push({ key: item, value: res.data.meta[item] });
      });
      setMeta(valuesObjKey);
    }
    if (res.data.meta1 !== null && res.data.meta1 !== "") {
      const valuesObjKey = [];
      Object.keys(res.data.meta1).forEach((item) => {
        if (item.length > 1)
          valuesObjKey.push({ key: item, value: res.data.meta1[item] });
      });
      setMeta1(valuesObjKey);
    }
    setAccountIds(res.data.accountIds);
  };
  useEffect(() => {
    initialiser();
    try {
      axios
        .get("https://console-api.theja.in/admin/getAllDeviceIds", {
          headers: {
            Authorization: localStorage.getItem("krgr_token"),
          },
        })
        .then((res) => {
          if (localStorage.getItem("deviceId")) {
            fetchDeviceHandler(localStorage.getItem("deviceId"));
          } else {
            fetchDeviceHandler(res.data[0].deviceId);
          }
          setTimeout(() => {
            setDeviceIds(res.data);
          }, 2000);
        });
    } catch (error) {
      toast.error("error! fetching deviceIds");
    }
  }, []);

  // this function is used to get device data by using device ID
  const fetchDeviceHandler = (deviceId) => {
    initialiser();
    localStorage.setItem("deviceId", deviceId);
    try {
      axios
        .get(`https://console-api.theja.in/admin/getDevice/${deviceId}`, {
          headers: {
            Authorization: localStorage.getItem("krgr_token"),
          },
        })
        .then((res) => {
          dataInitialiser(res);
        });
    } catch (e) {
      // handle error
      toast.error("Error fetching device!");
    } finally {
      toggleActive(deviceId);
      setTimeout(() => {
        setEdit(false);
        setloader(false);
      }, 2000);
    }
  };
  // to setup lightmode automatically
  useEffect(() => {
    let manualDarkMode = localStorage.getItem("krgr_iot_dark")
      ? JSON.parse(localStorage.getItem("krgr_iot_dark"))
      : null;

    // deprecating support older dark mode config, remove this after 2months
    if (typeof manualDarkMode === "string") {
      manualDarkMode = null;
    }

    if (manualDarkMode && manualDarkMode.expiry > new Date().getTime()) {
      setDarkMode(manualDarkMode.value);
    } else {
      const currentHour = new Date().getHours();
      const darkHours = [6, 7, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5, 6, 7];
      const darkMode = darkHours.includes(currentHour);
      setDarkMode(darkMode);
    }
  });
  // to highlight the select device
  function toggleActive(index) {
    setToggle(index);
  }
  function toggleActiveStyles(index) {
    if (index === toggle) return "card_view_active";
    else return "card_view";
  }

  const updateInitialiser = () => {
    setloader(true);
    device.meta = meta;
    device.meta1 = meta1;
  };
  // update Device
  const updateDeviceHandler = (deviceId) => {
    updateInitialiser();
    try {
      axios.put(
        `https://console-api.theja.in/admin/..../${deviceId}`,
        {
          device: device,
        },
        {
          headers: {
            Authorization: localStorage.getItem("krgr_token"),
          },
        }
      );
      toast.success("successfully Device updated");
    } catch (error) {
      toast.error("Error updating Device!");
    } finally {
      setTimeout(() => {
        setEdit(false);
        setloader(false);
      }, 2000);
    }
  };

  // Delete Device
  const deleteDeviceHandler = (deviceId) => {
    try {
      axios.delete(`https://console-api.theja.in/admin/..../${deviceId}`, {
        headers: {
          Authorization: localStorage.getItem("krgr_token"),
        },
      });
      toast.success("successfully Device Deleted");
    } catch (error) {
      toast.error("Error deleteing device!");
    }
  };
  const onSignOut = () => {
    try {
      // call login api
      localStorage.clear();
      navigate("/");
    } catch (e) {
      // clear input fields
    }
  };

  // remove device ID
  const removeDeviceIdHandler = (id) => {
    const updateDevice = accountIds.filter((item, index) => index !== id);
    setAccountIds(updateDevice);
  };
  // add device ids dynamically
  const addDeviceIdHandler = () => {
    setAccountIds([...accountIds, ""]);
  };
  const homeHandler = () => {
    localStorage.removeItem("deviceId");
    navigate("/dashboard");
  };
  return (
    <div
      className={
        darkMode ? "dashboard_page_view_ondark" : "dashboard_page_view"
      }
    >
      {/* Aside device ID's and Device name cards */}
      <div className={darkMode ? "aside_view_ondark" : "aside_view"}>
        <div
          className={
            !darkMode ? "w-[100%] p-2" : "w-[100%] p-2 bg-white rounded-md"
          }
        >
          <img src={darkMode ? LogoDark : Logo} alt="krgr-logo"></img>
        </div>
        <div className={isAdmin ? "home_button_search" : "w-full"}>
          <div className="home_button" onClick={homeHandler}>
            <HomeIcon sx={{ fontSize: 17 }} /> Home
          </div>
          <div
            className={
              darkMode ? "search_input_box_ondark" : "search_input_box"
            }
          >
            <input
              type="search"
              placeholder="search Device ID"
              className={`text-[.6rem]`}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div
          className="cards_container scrollbar-thin
     scrollbar-thumb-gray-400 scrollbar-thumb-rounded scrollbar-track-10"
        >
          {deviceIds &&
            deviceIds
              .filter((item) => item.name.toLowerCase().includes(search))
              .map((device, index) => (
                <div
                  key={index}
                  className={toggleActiveStyles(device.deviceId)}
                  onClick={() => fetchDeviceHandler(device.deviceId)}
                >
                  <div>
                    <div>Device Name</div>
                    <div>ID</div>
                  </div>
                  <div>
                    <div>:</div>
                    <div>:</div>
                  </div>
                  <div>
                    <div>{device.name}</div>
                    <div>{device.deviceId}</div>
                  </div>
                </div>
              ))}
        </div>
      </div>

      {/* main body for device data view */}
      <div className="main_body">
        {/* device view header part */}
        <div className={darkMode ? "header_darkMode" : "header"}>
          <div>
            Welcome to Our IoT Console{" "}
            <span className="user_name">
              {localStorage.getItem("userName")
                ? localStorage.getItem("userName")
                : "User"}
            </span>
          </div>
          <div className="mode_icon">
            {darkMode ? (
              <div
                title="Light mode"
                onClick={() => {
                  localStorage.setItem(
                    "krgr_iot_dark",
                    JSON.stringify({
                      value: false,
                      expiry: new Date().getTime() + 21600000,
                    })
                  );
                  setDarkMode(!darkMode);
                }}
              >
                <LightIcon />
              </div>
            ) : (
              <div
                title="Dark mode"
                onClick={() => {
                  localStorage.setItem(
                    "krgr_iot_dark",
                    JSON.stringify({
                      value: true,
                      expiry: new Date().getTime() + 21600000,
                    })
                  );
                  setDarkMode(!darkMode);
                }}
              >
                <DarkIcon />
              </div>
            )}
          </div>
          <div className="signout">
            <button onClick={onSignOut}>Sign Out</button>
          </div>
        </div>

        {/* device data with form */}
        {loader ? (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className="data_view_table">
            {/* device for table header with action buttons */}
            <div className="action_buttons">
              {edit ? (
                <button
                  className="save_btn"
                  onClick={() => updateDeviceHandler(device.deviceId)}
                >
                  Save
                </button>
              ) : (
                <button className="update_btn" onClick={() => setEdit(true)}>
                  Update
                </button>
              )}

              <button
                className="delete_btn"
                onClick={() => {
                  deleteDeviceHandler(device.deviceId);
                }}
              >
                Delete
              </button>
            </div>

            {/* device for table body with device data */}

            <div className="data_card_box scrollbar-thin scrollbar-thumb-gray-400 scrollbar-thumb-rounded scrollbar-track-10">
              <div className="data_view_cards_form">
                {/* device Id */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Device Id</div>
                  <div>
                    <input
                      className={"w-full input_value"}
                      type="text"
                      value={device.deviceId}
                      disabled
                    />
                  </div>
                </div>

                {/* device serial number */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Device Serial Number</div>
                  <div>
                    <input
                      className={
                        edit
                          ? " w-full h-full input_edit"
                          : "w-full input_value"
                      }
                      type="text"
                      placeholder="Please enter Device Serial Number"
                      value={
                        device.deviceSerialNumber
                          ? device.deviceSerialNumber
                          : edit
                          ? ""
                          : "Null"
                      }
                      onChange={(e) => {
                        setDevice({
                          ...device,
                          deviceSerialNumber: e.target.value,
                        });
                      }}
                      disabled={!edit}
                    />
                  </div>
                </div>

                {/* device name */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Device Name</div>
                  <div>
                    <input
                      className={
                        edit ? " w-full input_edit" : "w-full input_value"
                      }
                      placeholder="Please enter Device name"
                      type="text"
                      value={device.name ? device.name : edit ? "" : "Null"}
                      onChange={(e) => {
                        setDevice({ ...device, name: e.target.value });
                      }}
                      disabled={!edit}
                    />
                  </div>
                </div>

                {/* device Zone */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Zone</div>
                  <div>
                    <input
                      className={
                        edit ? " w-full input_edit" : "w-full input_value"
                      }
                      placeholder="Please enter zone"
                      type="text"
                      value={device.zone ? device.zone : edit ? "" : "Null"}
                      onChange={(e) => {
                        setDevice({ ...device, zone: e.target.value });
                      }}
                      disabled={!edit}
                    />
                  </div>
                </div>

                {/* device State */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Device State</div>
                  <div
                    className={
                      device.deviceState ? "device_active" : "device_inactive"
                    }
                  >
                    <nav></nav>
                  </div>
                </div>

                {/* Last device state */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Last Device State</div>
                  <div>
                    <input
                      className={"w-full input_value"}
                      type="text"
                      placeholder="Last Device State"
                      value={
                        device.lastDeviceState ? device.lastDeviceState : "Null"
                      }
                      disabled
                    />
                  </div>
                </div>

                {/* Given State */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Given State</div>
                  <div
                    className={
                      device.givenState ? "device_active" : "device_inactive"
                    }
                  >
                    <nav></nav>
                  </div>
                </div>

                {/* Last devGiverice state */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Last Given State</div>
                  <div>
                    <input
                      className={"w-full input_value"}
                      placeholder="Last Given State"
                      type="text"
                      value={
                        device.lastGivenState ? device.lastGivenState : "Null"
                      }
                      disabled
                    />
                  </div>
                </div>
                {/* Power available */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Power Available</div>
                  <div
                    className={
                      device.powerAvailable
                        ? "device_active"
                        : "device_inactive"
                    }
                  >
                    <nav></nav>
                  </div>
                </div>

                {/* Last Power available */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Last Power Available</div>
                  <div>
                    <input
                      className={"w-full input_value"}
                      type="text"
                      placeholder="Power Available"
                      value={
                        device.lastPowerAvailable
                          ? device.lastPowerAvailable
                          : "Null"
                      }
                      disabled
                    />
                  </div>
                </div>

                {/* Min Current */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Min Current</div>
                  <div>
                    <input
                      className={"w-full input_value"}
                      placeholder=" min current"
                      type="text"
                      value={device.minCurrent ? device.minCurrent : "Null"}
                      disabled
                    />
                  </div>
                </div>
                {/* Max Current */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Max Current</div>
                  <div>
                    <input
                      className={"w-full input_value"}
                      placeholder=" max current"
                      type="text"
                      value={device.maxCurrent ? device.maxCurrent : "Null"}
                      disabled
                    />
                  </div>
                </div>

                {/* Min Voltage */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Min Voltage</div>
                  <div>
                    <input
                      className={"w-full input_value"}
                      placeholder=" min voltage"
                      type="text"
                      value={device.minVoltage ? device.minVoltage : "Null"}
                      disabled
                    />
                  </div>
                </div>
                {/* Max Voltage */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Max Voltage</div>
                  <div>
                    <input
                      className={"w-full input_value"}
                      placeholder=" max voltage"
                      type="text"
                      value={device.maxVoltage ? device.maxVoltage : "Null"}
                      disabled
                    />
                  </div>
                </div>

                {/* Device Last Ping */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Device Last Ping</div>
                  <div>
                    <input
                      className={"w-full input_value"}
                      placeholder="Device Last Ping"
                      type="text"
                      value={
                        device.deviceLastPing ? device.deviceLastPing : "Null"
                      }
                      disabled
                    />
                  </div>
                </div>

                {/* Load Last Ping */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Load Last Ping</div>
                  <div>
                    <input
                      className={"w-full input_value"}
                      type="text"
                      placeholder="Load Last Ping"
                      value={device.loadLastPing ? device.loadLastPing : "Null"}
                      disabled
                    />
                  </div>
                </div>

                {/* Power Last Ping */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Power Last Ping</div>
                  <div>
                    <input
                      className={"w-full input_value"}
                      placeholder="Power Last Ping"
                      type="text"
                      value={
                        device.powerLastPing ? device.powerLastPing : "Null"
                      }
                      disabled
                    />
                  </div>
                </div>

                {/* Last Ping */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Last Ping</div>
                  <div>
                    <input
                      className={"w-full input_value"}
                      placeholder="Last Ping"
                      type="text"
                      value={device.lastPing ? device.lastPing : "Null"}
                      disabled
                    />
                  </div>
                </div>

                {/* Device Sleep Time */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Device Sleep Time</div>
                  <div>
                    <input
                      className={"w-full input_value"}
                      type="text"
                      placeholder="Device Sleep Time"
                      value={
                        device.deviceSleepTime ? device.deviceSleepTime : "Null"
                      }
                      disabled
                    />
                  </div>
                </div>
                {/* Nth Message */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Nth Message</div>
                  <div>
                    <input
                      className={"w-full input_value"}
                      type="text"
                      placeholder="Nth Message"
                      value={device.nthMessage ? device.nthMessage : "Null"}
                      disabled
                    />
                  </div>
                </div>

                {/* Sim ID */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Sim ID</div>
                  <div>
                    <input
                      className={"w-full input_value"}
                      type="text"
                      placeholder="Sim ID"
                      value={device.simId ? device.simId : "Null"}
                      disabled
                    />
                  </div>
                </div>

                {/* active */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Active</div>
                  <div
                    className={
                      device.active ? "device_active" : "device_inactive"
                    }
                  >
                    <nav></nav>
                  </div>
                </div>

                {/* Topic */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Topic</div>
                  <div>
                    <input
                      className={"w-full input_value"}
                      type="text"
                      placeholder="topic"
                      value={device.Topic ? device.Topic : "Null"}
                      disabled
                    />
                  </div>
                </div>

                {/* AccountIds */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Account Id's</div>
                  <div className="">
                    <nav className="account_ids">
                      {accountIds &&
                        accountIds.map((key, index, arr) => (
                          <nav key={index}>
                            <input
                              className={
                                edit ? "w-[90%] input_edit" : "input_value"
                              }
                              placeholder="Enter ID"
                              type="text"
                              value={key ? key : edit ? "" : "Null"}
                              onChange={(e) => {
                                const clonedArray = [...arr];
                                clonedArray[index] = e.target.value;
                                setAccountIds(clonedArray);
                              }}
                              disabled={!edit}
                            />
                            {edit && (
                              <span
                                className="remove_button"
                                id="scrollbar-hide"
                                onClick={() => removeDeviceIdHandler(index)}
                              >
                                &times;
                              </span>
                            )}
                          </nav>
                        ))}
                    </nav>
                  </div>
                  {edit && (
                    <>
                      <span></span>
                      <span className="add_more">
                        <span></span>
                        <span onClick={addDeviceIdHandler}>+</span>
                      </span>
                    </>
                  )}
                </div>

                {/* Subscription Validity */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Subscription Validity</div>
                  <div>
                    <input
                      className={
                        edit
                          ? "text-dark w-full input_edit"
                          : "w-full input_value"
                      }
                      type="date"
                      value={
                        device.subscriptionValidity
                          ? DateFormatForInput(device.subscriptionValidity)
                          : edit
                          ? ""
                          : "Null"
                      }
                      onChange={(e) => {
                        setDevice({
                          ...device,
                          subscriptionValidity: DateFormatForApi(
                            e.target.value
                          ),
                        });
                      }}
                      disabled={!edit}
                    />
                  </div>
                </div>

                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Meta Check Required</div>
                  <div
                    className={
                      device.metaCheckRequired
                        ? "device_active"
                        : "device_inactive"
                    }
                  >
                    <nav></nav>
                  </div>
                </div>
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Meta1 Check Required</div>
                  <div
                    className={
                      device.meta1CheckRequired
                        ? "device_active"
                        : "device_inactive"
                    }
                  >
                    <nav></nav>
                  </div>
                </div>
                {/* Device Model */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Device Model</div>
                  <div>
                    <input
                      className={"w-full input_value"}
                      type="text"
                      placeholder="Device Mode"
                      value={device.deviceModel ? device.deviceModel : "Null"}
                      disabled
                    />
                  </div>
                </div>
                {/* Last Meta Check */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Last Meta Check</div>
                  <div>
                    <input
                      className={"w-full input_value"}
                      type="text"
                      placeholder="Last Meta Check"
                      value={
                        device.lastMetaCheck ? device.lastMetaCheck : "Null"
                      }
                      disabled
                    />
                  </div>
                </div>

                {/* Meta Check Event Sent */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Meta Check Event Sent</div>
                  <div>
                    <input
                      className={"w-full input_value"}
                      type="text"
                      placeholder="Meta Check Event Sent"
                      value={
                        device.metaCheckEventSent
                          ? device.metaCheckEventSent
                          : "Null"
                      }
                      disabled
                    />
                  </div>
                </div>

                {/* Meta1 */}
                <div
                  className={
                    darkMode ? "data_card_dark meta" : "data_card meta"
                  }
                >
                  <div>Meta1</div>
                  <div className="flex flex-col gap-1">
                    {meta1 &&
                      meta1.map((obj, index, arr) => (
                        <nav key={index} className=" flex flex-row gap-1">
                          <input
                          placeholder={`${obj.key}`}
                            title={obj.key}
                            className={
                              edit
                                ? "meta_input w-full h-7 input_edit"
                                : "w-full h-7 input_value meta_input"
                            }
                            
                            type="text"
                            value={obj.key ? obj.key : edit ? "" : "Null"}
                            onChange={(e) => {
                              const clonedArray = [...arr];
                              clonedArray[index].key = e.target.value;
                              setMeta1(clonedArray);
                            }}
                            disabled={!edit}
                          />

                          <input
                            title={obj.value}
                            placeholder={`Enter ${obj.key}`}
                            className={
                              edit
                                ? "meta_input w-full h-7 input_edit"
                                : "meta_input w-full h-7 input_value"
                            }
                            type="text"
                            value={obj.value ? obj.value : edit ? "" : "Null"}
                            onChange={(e) => {
                              const clonedArray = [...arr];
                              clonedArray[index].value = e.target.value;
                              setMeta1(clonedArray);
                            }}
                            disabled={!edit}
                          />
                        </nav>
                      ))}
                  </div>
                </div>
                {/* Meta */}
                <div
                  className={
                    darkMode ? "data_card_dark meta" : "data_card meta"
                  }
                >
                  <div>Meta</div>
                  <div className="flex flex-col gap-1">
                    {meta &&
                      meta.map((obj, index, arr) => (
                        <nav key={index} className="flex flex-row gap-1">
                          <input
                          placeholder={`${obj.key}`}
                            title={obj.key}
                            className={
                              edit
                                ? " w-full h-7 input_edit meta_input"
                                : "w-full h-7 input_value meta_input"
                            }
                            type="text"
                            value={obj.key ? obj.key : edit ? "" : "Null"}
                            onChange={(e) => {
                              const clonedArray = [...arr];
                              clonedArray[index].key = e.target.value;
                              setMeta(clonedArray);
                            }}
                            disabled={!edit}
                          />

                          <input
                          placeholder={`Enter ${obj.key}`}
                            title={obj.value}
                            className={
                              edit
                                ? "meta_input w-full h-7 input_edit"
                                : "w-full h-7 input_value meta_input"
                            }
                            type="text"
                            value={obj.value ? obj.value : edit ? "" : "Null"}
                            onChange={(e) => {
                              const clonedArray = [...arr];
                              clonedArray[index].value = e.target.value;
                              setMeta(clonedArray);
                            }}
                            disabled={!edit}
                          />
                        </nav>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default ManageDevices;
