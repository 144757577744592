import React, { useContext, useEffect, useState } from "react";
import Logo from "../../assets/logo.png";
import LogoDark from "../../assets/logobw.png";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import { DarkIcon, LightIcon, Spinner } from "../../utils/DarkAndLightIcon";
import { Context } from "../../App";
import { Toaster, toast } from "react-hot-toast";
import axios from "axios";

function ManageUsers() {
  const [darkMode, setDarkMode] = useContext(Context);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [edit, setEdit] = useState(false);
  const [userIds, setUserIds] = useState([]);
  const [user, setUser] = useState([]);
  const [deviceIds, setDeviceIds] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [address, setAddress] = useState([{ key: "", value: "" }]);
  const [toggle, setToggle] = useState();
  const [loader, setloader] = useState(false);

  // to setup lightmode automatically
  useEffect(() => {
    let manualDarkMode = localStorage.getItem("krgr_iot_dark")
      ? JSON.parse(localStorage.getItem("krgr_iot_dark"))
      : null;

    // deprecating support older dark mode config, remove this after 2months
    if (typeof manualDarkMode === "string") {
      manualDarkMode = null;
    }

    if (manualDarkMode && manualDarkMode.expiry > new Date().getTime()) {
      setDarkMode(manualDarkMode.value);
    } else {
      const currentHour = new Date().getHours();
      const darkHours = [6,7, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5, 6, 7];
      const darkMode = darkHours.includes(currentHour);
      setDarkMode(darkMode);
    }
  });

  // initializer
  const initialiser=()=>{
    setUser('')
    setDeviceIds("")
    setUserDetails("")
    setAddress("")
    setSearch("")
    setEdit(false)
    setloader(true)
  }

  const userDataInitialiser=(response)=>{
    setUser(response.data);
    if (response.data.userDetails) {
      const valuesObjKey = [];
      Object.keys(response.data.userDetails).forEach((item) => {
        if (item.length > 1 && item !== "address") {
          valuesObjKey.push({
            key: item,
            value: response.data.userDetails[item],
          });
        } else if (response.data.userDetails.address) {
          const valuesObjKey = [];
          Object.keys(response.data.userDetails.address).forEach((item) => {
            if (item.length > 1)
              valuesObjKey.push({
                key: item,
                value: response.data.userDetails.address[item],
              });
          });

          setAddress(valuesObjKey);
        }
      });
      setUserDetails(valuesObjKey);
    }
    setDeviceIds(response.data.deviceIds);
  }

  useState(() => {
    initialiser()
    try {
      axios
        .get("https://console-api.theja.in/admin/getAllUserIds", {
          headers: {
            Authorization: localStorage.getItem("krgr_token"),
          },
        })
        .then((res) => {
           if(localStorage.getItem('userId')){
            userHandler(localStorage.getItem('userId'))
          }
          else{
            userHandler(res.data[0].mobile);
          }
          
          setTimeout(() => {
            setUserIds(res.data);
          }, 2000);
        });
    } catch (error) {
      toast.error("error! fetching userIds");
    }
  }, []);

  // // By click user fetch user data
  const userHandler = (userId) => {
    initialiser()
    localStorage.setItem("userId", userId);
    try {
      axios
        .get(`https://console-api.theja.in/admin/getUser/${userId}`, {
          headers: {
            Authorization: localStorage.getItem("krgr_token"),
          },
        })
        .then((res) => {
        userDataInitialiser(res)
        });
    } catch (e) {
      // handle error
      toast.error("Error fetching user!");
    } finally {
      toggleActive(userId);
      setTimeout(() => {
        setEdit(false)
        setloader(false);
      }, 2000);
    }
  };
  function toggleActive(index) {
    setToggle(index);
  }
  function toggleActiveStyles(index) {
    if (index === toggle) return "card_view_active";
    else return "card_view";
  }

  const updateValueInitialiser=()=>{
    setloader(true);
    const valuesObj = [];
    deviceIds.forEach((item) => {
      if (item.length > 1) valuesObj.push(item);
    });
    setDeviceIds(valuesObj)
    user.deviceIds=deviceIds
    setUser({...user,userDetails:userDetails})
    setUser({...user,userDetails:{address:address}})
  }
  // update user
  const updateUserHandler = (userId) => {
    updateValueInitialiser()
    try {
      axios
        .put(
          `https://console-api.theja.in/admin/..../${userId}`,
          {
            user: user,
          },
          {
            headers: {
              Authorization: localStorage.getItem("krgr_token"),
            },
          }
        )
         toast.success("successfully user updated");
    } catch (error) {
      toast.error("Error updating user!");
    } finally {
      setTimeout(() => {
            setEdit(false);
            setloader(false);
          }, 2000);
    }
  };

  // Delete user
  const deleteUserHandler = (userId) => {
    try {
      axios.delete(`https://console-api.theja.in/admin/..../${userId}`, {
        headers: {
          Authorization: localStorage.getItem("krgr_token"),
        },
      });
      toast.success("successfully user Deleted");
    } catch (error) {
      toast.error("Error deleteing user!");
    }
  };
  const onSignOut = () => {
    try {
      // call login api
      localStorage.clear();
      navigate("/");
    } catch (e) {
      // clear input fields
    }
  };

  // remove device ID
  const removeDeviceIdHandler = (id) => {
    if (deviceIds.length > 1) {
      const updateDevice = deviceIds.filter((item, index) => index !== id);
      setDeviceIds(updateDevice);
    } else {
      alert("You have atleast on deviceID");
    }
  };
  // add device ids dynamically
  const addDeviceIdHandler = () => {
    setDeviceIds([...deviceIds, ""]);
  };
   const homeHandler=()=>{
  localStorage.removeItem('userId');
  navigate('/dashboard')
 }
  return (
    <div
      className={
        darkMode ? "dashboard_page_view_ondark" : "dashboard_page_view"
      }
    >
      <div className={darkMode ? "aside_view_ondark" : "aside_view"}>
        <div
          className={
            !darkMode ? "w-[100%] p-2" : "w-[100%] p-2 bg-white rounded-md"
          }
        >
          <Toaster />
          <img src={darkMode ? LogoDark : Logo} alt="krgr-logo"></img>
        </div>
        <div className={"home_button_search"}>
          <div className="home_button" onClick={homeHandler}>
            <HomeIcon sx={{ fontSize: 17 }} /> Home
          </div>

          <div
            className={
              darkMode ? "search_input_box_ondark" : "search_input_box"
            }
          >
            <input
              type="search"
              placeholder="search user name"
              className={`text-[.6rem]`}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="cards_container scrollbar-thin scrollbar-thumb-gray-400 scrollbar-thumb-rounded scrollbar-track-10">
          {userIds
            .filter((item) => item.name.toLowerCase().includes(search))
            .map((user, index) => (
              <div
                className={toggleActiveStyles(user.mobile)}
                onClick={() => userHandler(user.mobile)}
                key={index}
              >
                <div>
                  <div>User Name</div>
                  <div>ID</div>
                </div>
                <div>
                  <div>:</div>
                  <div>:</div>
                </div>
                <div>
                  <div>{user.name}</div>
                  <div>{user.mobile}</div>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* main body for device data view */}
      <div className="main_body">
        
         {/* device view header part */}
        <div className={darkMode ? "header_darkMode" : "header"}>
          <div>
            Welcome to Our IoT Console{" "}
            <span className="user_name">
              {localStorage.getItem("userName")
                ? localStorage.getItem("userName")
                : "User"}
            </span>
          </div>
          <div className="mode_icon">
            {darkMode ? (
              <div
                title="Light mode"
                onClick={() => {
                  localStorage.setItem(
                    "krgr_iot_dark",
                    JSON.stringify({
                      value: false,
                      expiry: new Date().getTime() + 21600000,
                    })
                  );
                  setDarkMode(!darkMode);
                }}
              >
                <LightIcon />
              </div>
            ) : (
              <div
                title="Dark mode"
                onClick={() => {
                  localStorage.setItem(
                    "krgr_iot_dark",
                    JSON.stringify({
                      value: true,
                      expiry: new Date().getTime() + 21600000,
                    })
                  );
                  setDarkMode(!darkMode);
                }}
              >
                <DarkIcon />
              </div>
            )}
          </div>
          <div className="signout">
            <button onClick={onSignOut}>Sign Out</button>
          </div>
        </div>
        
        {/* device data with form */}
        {loader ? (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className="data_view_table">
             {/* device for table header with action buttons */}
            <div className="action_buttons">
              {edit ? (
                <button
                  className="save_btn"
                  onClick={() => updateUserHandler(user.mobile)}
                >
                  Save
                </button>
              ) : (
                <button className="update_btn" onClick={() => setEdit(true)}>
                  Update
                </button>
              )}
              <button
                className="delete_btn"
                onClick={() => {
                  deleteUserHandler(user.mobile);
                }}
              >
                Delete
              </button>
            </div>
            <div className="data_card_box scrollbar-thin scrollbar-thumb-gray-400 scrollbar-thumb-rounded scrollbar-track-10">
              <div className="data_view_cards_form">
                {/* User Mobile */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Mobile</div>
                  <div>
                    <input
                      className={"w-full input_value"}
                      type="text"
                      value={user.mobile}
                      disabled
                    />
                  </div>
                </div>

                {/* User Email */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Email</div>
                  <div>
                    <input
                      className={
                        edit
                          ? " w-full h-full input_edit"
                          : "w-full input_value"
                      }
                      type="text"
                      placeholder={`Please enter email`}
                      value={user.email ? user.email : edit ? "" : "Null"}
                      onChange={(e) => {
                        setUser({ ...user, email: e.target.value });
                      }}
                      disabled={!edit}
                    />
                  </div>
                </div>

                {/* User Role */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Role</div>
                  <div>
                    <input
                      className={
                        edit
                          ? " w-full h-full input_edit"
                          : "w-full input_value"
                      }
                      placeholder={`Please enter role`}
                      type="text"
                      value={user.role ? user.role : edit ? "" : "Null"}
                      onChange={(e) => {
                        setUser({ ...user, role: e.target.value });
                      }}
                      disabled={!edit}
                    />
                  </div>
                </div>

                {/* User Active Status */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Active</div>
                  <div
                    className={
                      user.active ? "device_active" : "device_inactive"
                    }
                  >
                    <nav></nav>
                  </div>
                </div>

                {/* User Pin */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Pin</div>
                  <div>
                    <input
                      className={
                        edit
                          ? " w-full h-full input_edit"
                          : "w-full input_value"
                      }
                      placeholder={`Please enter pin`}
                      type="text"
                      value={user.pin ? user.pin : edit ? "" : "Null"}
                      onChange={(e) => {
                        setUser({ ...user, pin: e.target.value });
                      }}
                      disabled={!edit}
                    />
                  </div>
                </div>
                {/* User Preferred Language */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Preferred Language</div>
                  <div>
                    <input
                      className={
                        edit
                          ? " w-full h-full input_edit"
                          : "w-full input_value"
                      }
                      type="text"
                      placeholder={`Please enter Preferred language`}
                      value={
                        user.preferredLanguage
                          ? user.preferredLanguage
                          : edit
                          ? ""
                          : "Null"
                      }
                      onChange={(e) => {
                        setUser({ ...user, preferredLanguage: e.target.value });
                      }}
                      disabled={!edit}
                    />
                  </div>
                </div>

                {/* user device IDs */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Device Id's</div>
                  <div className="">
                    <nav className="account_ids">
                      {deviceIds &&
                        deviceIds.map((key, index, arr) => (
                          <nav key={index}>
                            <input
                              className={
                                edit ? "w-[90%] input_edit" : "input_value"
                              }
                              type="text"
                              placeholder={`enter ID`}
                              value={key ? key : edit ? "" : "Null"}
                              onChange={(e) => {
                                const clonedArray = [...arr];
                                clonedArray[index] = e.target.value;
                                setDeviceIds(clonedArray);
                              }}
                              disabled={!edit}
                            />
                            {edit && (
                              <span
                                className="remove_button"
                                onClick={() => removeDeviceIdHandler(index)}
                              >
                                &times;
                              </span>
                            )}
                          </nav>
                        ))}
                    </nav>
                  </div>
                  {edit && (
                    <>
                      <span></span>
                      <span className="add_more">
                        <span></span>
                        <span onClick={addDeviceIdHandler}>+</span>
                      </span>
                    </>
                  )}
                </div>

                {/* user zone */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Zone</div>
                  <div>
                    <input
                      className={
                        edit
                          ? " w-full h-full input_edit"
                          : "w-full input_value"
                      }
                      placeholder={`Please enter zone`}
                      type="text"
                      value={user.zone ? user.zone : edit ? "" : "Null"}
                      onChange={(e) => {
                        setUser({ ...user, zone: e.target.value });
                      }}
                      disabled={!edit}
                    />
                  </div>
                </div>

                {/* user Queue Name */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Queue Name</div>
                  <div>
                    <input
                      className={
                        edit
                          ? " w-full h-full input_edit"
                          : "w-full input_value"
                      }
                      type="text"
                      placeholder={`Please enter Queue name`}
                      value={
                        user.queueName ? user.queueName : edit ? "" : "Null"
                      }
                      onChange={(e) => {
                        setUser({ ...user, queueName: e.target.value });
                      }}
                      disabled={!edit}
                    />
                  </div>
                </div>

                {/* user Subscription Validity */}
                <div className={darkMode ? "data_card_dark" : "data_card"}>
                  <div>Subscription Validity</div>
                  <div>
                    <input
                      className={
                        edit
                          ? "text-dark w-full input_edit"
                          : "w-full input_value"
                      }
                      type="date"
                      placeholder={`Please enter ${user.subscriptionValidity}`}
                      value={
                        user.subscriptionValidity
                          ? user.subscriptionValidity
                          : edit
                          ? ""
                          : "Null"
                      }
                      onChange={(e) => {
                        setUser({
                          ...user,
                          subscriptionValidity: e.target.value,
                        });
                      }}
                      disabled={!edit}
                    />
                  </div>
                </div>

                {/* user details */}

                {userDetails && (
                  <div
                    className={
                      darkMode ? "data_card_dark_user" : "data_card_user"
                    }
                  >
                    <div className="flex text-[1rem] justify-center font-semibold">
                      User Details
                    </div>
                    {userDetails.map(
                      (key, index, arr) =>
                        key.key !== "address" && (
                          <div className="user_info_labels" key={index}>
                            <div>{key.key}</div>

                            <div title={key.value}>
                              {key.value ? key.value : "Null"}
                            </div>
                          </div>
                        )
                    )}
                    {address && (
                      <div
                        className={
                          darkMode
                            ? "user_address_view_ondark"
                            : "user_address_view"
                        }
                      >
                        <nav className="p-1 font-semibold">Address</nav>
                        <div
                          className={
                            darkMode ? "address_view_ondark" : "address_view"
                          }
                        >
                          {userDetails.map(
                            (key, index, arr) =>
                              key.key !== "address" && (
                                <nav className="user_info_labels" key={index}>
                                  <div>{key.key}</div>
                                  <div>
                                    <input
                                      className={
                                        edit
                                          ? "text-dark w-full input_edit"
                                          : "w-full input_value"
                                      }
                                      type="text"
                                      placeholder={`Please enter ${key.key}`}
                                      title={key.value}
                                      value={key.value ? key.value :edit?"":"Null"}
                                      onChange={(e) => {
                                        const clonedArray = [...arr];
                                        clonedArray[index].value =
                                          e.target.value;
                                        setUserDetails(clonedArray);
                                      }}
                                      disabled={!edit}
                                    />
                                  </div>
                                </nav>
                              )
                          )}

                          <div className="address_table">
                            <nav className="pl-2">Address</nav>
                            <div className="address_table_data">
                              {address.map((key, index, arr) => (
                                <nav
                                  key={index}
                                  className="address_table_data_view"
                                >
                                  <div>{key.key ? key.key : "Null"}</div>
                                    <div>
                                      <input
                                      className={
                                        edit
                                          ? "text-dark w-full input_edit"
                                          : "w-full input_value"
                                      }
                                        title={key.value}
                                        type="text"
                                        placeholder={`Please enter ${key.key}`}
                                        value={key.value ? key.value :edit?"":"Null"}
                                        onChange={(e) => {
                                          const clonedArray = [...arr];
                                          clonedArray[index].value =
                                            e.target.value;
                                          setAddress(clonedArray);
                                        }}
                                        disabled={!edit}
                                      />
                                    </div>
                                </nav>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default ManageUsers;
